'use client';
import Image from "next/image";
import Link from "next/link";
import { useState } from "react";

const NAV_LIST = [
  { label: "Home", href: "/", icon: "ri-home-5-line"},
  { label: "About Me", href: "/about-me", icon:"ri-game-line"},
  { label: "Work", href: "/portfolio", icon: "ri-artboard-line"},
  // { label: "Freelance", href: "/services", icon: "ri-server-line"},
  { label: "Blog", href: "https://code.aryn.tech/", icon: "ri-booklet-line", target: "_blank"},
  { label: "LinkedIn", href: "https://linkedin.com/in/thehungrybird/", icon: "ri-linkedin-line", target: "_blank"},
  // { label: "Résumé", href: "/docs/cv-cet.pdf", icon: "ri-profile-line", target: "_blank"},
  { label: "GitHub", href: "https://github.com/kulterryan/", icon: "ri-github-line", target: "_blank"},
  { label: "Topmate", href: "https://topmate.io/thehungrybird/", icon: "ri-graduation-cap-line", target: "_blank"},
]

function Header (){
  // Mobile Menu Trigger
  const [active, setActive] = useState(false);

  function activeIT(){
    setActive(!active);
  }
  
  return (
    <>
      <header className="sm:py-2 px-0 md:py-2 md:px-2 md:fixed sm:right-0 left-0 top-0 items-center gap-5 z-10 sm:bg-blue-700 sm:bg-opacity-20 my-5 sm:m-0">
        {/* Navigation */}
        <nav className="sm:px-20">
          {/* Desktop Header */}
          <div id="desktop" className="md:flex sm:justify-between hidden">
            <div>
              <Link href={"/"}>
                <Image src="/images/Signature.png" alt="Aryan Chaurasia" className="rounded-full" width={200} height={75}/>
              </Link>
              </div>
            <div className="md:flex sm:justify-end items-center gap-5">
              { NAV_LIST.map(nav_links => (
                <Link key={nav_links.label} href={nav_links.href} target={nav_links.target} className="align-baseline flex gap-x-1"><i className={nav_links.icon}></i><span className={`hover:underline underline-offset-4`}>{nav_links.label}</span></Link>
                ))
              }
            </div>
          </div>

          {/* Mobile Menu */}
          <div id="mobile" className={`md:hidden flex justify-between align-middle`}>
            <div className="flex">
              {/* Menu Button */}
              <button onClick={() => setActive(!active)} title="Mobile Menu" type="button" aria-controls="mobile-menu" className="z-10 m-0 hover:m-0 relative bg-gray-900 bg-opacity-30" aria-expanded="false">
                {/* <span>Open Mobile Menu</span> */}
                {!active ? ( <i className="ri-bar-chart-horizontal-line ri-xl"></i>): ( <i className="ri-close-line ri-xl opacity-80"></i> )}
              </button>

              <div className={!active ? "hidden" : "fixed"}>
                <div className="gap-3 absolute px-10 py-12 bg-black bg-blend-overlay opacity-90">
                  { NAV_LIST.map(nav_links => (
                    <span key={nav_links.label}><Link href={nav_links.href}  onClick={activeIT} className="align-baseline flex gap-x-1 text-xl"><i className={nav_links.icon}></i>{nav_links.label}</Link></span>
                  ))}
                </div>
              </div>
            </div>
            <Link href={"/"} >
              <Image src="/images/Signature.png" alt="Aryan Chaurasia" className="rounded-full" width={150} height={150}/>
            </Link>
          </div>
        </nav>
      </header>
    </>
  );
}

export default Header;